@font-face {
  font-family: "UniversalSans";
  src: url("./fonts/UniversalSans-v1-2-20-0-0-221112212112-01-550.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "UniversalSans";
  src: url("./fonts/UniversalSans-v1-2-20-0-0-221112212112-01-750.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "UniversalSans", Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

body {
  line-height: 1.5;
}

:focus {
  outline: none;
}
