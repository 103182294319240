.preview-panel {
  height: 100%;
  /*min-height: 100vh;*/
  width: 100%;
}

.preview-frame {
  width: 100%;
  height: 100%;
  box-shadow: none;
  border: none;
}

.content {
  padding: 1rem;
}


.title {
  margin-bottom: 1em;
  text-align: center;
}

.subtitle {
  text-align: center;
}


.icon {
  vertical-align: middle;
  font-size: 1.25rem;
  margin: 0.25rem;
}
